import React, { FC, useEffect } from 'react'
import { Button, Flex, Heading, Text } from '@chakra-ui/react'
import { graphql, navigate } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  Moon,
  MoonCrater1,
  MoonCrater2,
  MoonCrater3,
  Star1,
  Star2,
  Star3,
  Star4,
  Star5,
  Error,
  Astronaut,
  AstronautBackpack,
  AstronautArmLeft1,
  AstronautLegLeft,
  AstronautFootLeft,
  AstronautArmLeft2,
  AstronautArmRight1,
  AstronautArmRight2,
  AstronautArmThumbLeft,
  AstronautArmThumbRight,
  AstronautBody,
  AstronautBodyChest,
  AstronautFootRight,
  AstronautHead,
  AstronautHeadFlare1,
  AstronautHeadFlare2,
  AstronautLegRight,
  AstronautWristLeft,
  AstronautWristRight,
  NotFoundContainer,
  AstronautCord,
} from '../styles/pages/404style'
import Layout from 'components/Layout'

const NotFoundPage: FC = () => {
  const { t, i18n } = useTranslation()
  const homePath = i18n.language === 'fr' ? '/fr' : '/'

  useEffect(() => {
    function drawVisor() {
      const canvas = document.getElementById(
        'visor'
      ) as HTMLCanvasElement | null
      const ctx = canvas?.getContext('2d')

      if (ctx) {
        ctx.beginPath()
        ctx.moveTo(5, 45)
        ctx.bezierCurveTo(15, 64, 45, 64, 55, 45)

        ctx.lineTo(55, 20)
        ctx.bezierCurveTo(55, 15, 50, 10, 45, 10)

        ctx.lineTo(15, 10)

        ctx.bezierCurveTo(15, 10, 5, 10, 5, 20)
        ctx.lineTo(5, 45)

        ctx.fillStyle = '#2f3640'
        ctx.strokeStyle = '#f5f6fa'
        ctx.fill()
        ctx.stroke()
      }
    }

    const cordCanvas = document.getElementById(
      'cord'
    ) as HTMLCanvasElement | null
    const ctx = cordCanvas?.getContext('2d')

    let y1 = 160
    let y2 = 100
    let y3 = 100

    let y1Forward = true
    let y2Forward = false
    let y3Forward = true

    function animate() {
      requestAnimationFrame(animate)
      if (ctx) {
        ctx.clearRect(0, 0, innerWidth, innerHeight)

        ctx.beginPath()
        ctx.moveTo(130, 170)
        ctx.bezierCurveTo(250, y1, 345, y2, 400, y3)

        ctx.strokeStyle = 'white'
        ctx.lineWidth = 8
        ctx.stroke()
      }

      if (y1 === 100) {
        y1Forward = true
      }

      if (y1 === 300) {
        y1Forward = false
      }

      if (y2 === 100) {
        y2Forward = true
      }

      if (y2 === 310) {
        y2Forward = false
      }

      if (y3 === 100) {
        y3Forward = true
      }

      if (y3 === 317) {
        y3Forward = false
      }

      y1Forward ? (y1 += 1) : (y1 -= 1)
      y2Forward ? (y2 += 1) : (y2 -= 1)
      y3Forward ? (y3 += 1) : (y3 -= 1)
    }

    drawVisor()
    animate()
  }, [])
  return (
    <Layout onlyContent>
      <NotFoundContainer bg="black">
        <Moon />
        <MoonCrater1 />
        <MoonCrater2 />
        <MoonCrater3 />

        <Star1 />
        <Star2 />
        <Star3 />
        <Star4 />
        <Star5 />

        <Error>
          <Heading size="4xl" as="h1" mb={['1', '2']}>
            {t('404.title')}
          </Heading>
          <Text size="xxl" fontWeight="500" mb={['1', '2']}>
            {t('404.subtitle')}
          </Text>
          <Text size="lg" mb="8">
            {t('404.description')}
          </Text>
          <Flex>
            <Button
              size="lg"
              variant="primary"
              onClick={() => navigate(homePath)}
              mr="4"
            >
              {t('404.buttonHome')}
            </Button>
            <Button
              size="lg"
              variant="black"
              onClick={() => {
                navigate(homePath, {
                  state: {
                    scrollTo: 'main-form',
                  },
                })
              }}
            >
              {t('404.buttonContact')}
            </Button>
          </Flex>
        </Error>

        <Astronaut>
          <AstronautBackpack />
          <AstronautBody />
          <AstronautBodyChest />
          <AstronautArmLeft1 />
          <AstronautArmLeft2 />
          <AstronautArmRight1 />
          <AstronautArmRight2 />
          <AstronautArmThumbLeft />
          <AstronautArmThumbRight />
          <AstronautLegLeft />
          <AstronautLegRight />
          <AstronautFootLeft />
          <AstronautFootRight />
          <AstronautWristLeft />
          <AstronautWristRight />

          <AstronautCord>
            <canvas id="cord" height="500px" width="500px"></canvas>
          </AstronautCord>

          <AstronautHead>
            <canvas id="visor" width="60px" height="60px"></canvas>
            <AstronautHeadFlare1 />
            <AstronautHeadFlare2 />
          </AstronautHead>
        </Astronaut>
      </NotFoundContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default NotFoundPage
