import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const NotFoundContainer = styled(Box)`
  height: 100vh;
  position: relative;
  overflow: hidden;
`

export const Moon = styled.div`
  background: linear-gradient(
    90deg,
    rgba(208, 208, 208, 1) 48%,
    rgba(145, 145, 145, 1) 100%
  );
  position: absolute;
  top: -100px;
  left: -300px;
  width: 900px;
  height: 900px;
  border-radius: 100%;
  box-shadow: 0px 0px 30px -4px rgba(0, 0, 0, 0.5);
`

export const MoonCrater = styled.div`
  position: absolute;
  border-radius: 100%;
  background: linear-gradient(
    90deg,
    rgba(122, 122, 122, 1) 38%,
    rgba(195, 195, 195, 1) 100%
  );
  opacity: 0.6;
`

export const MoonCrater1 = styled(MoonCrater)`
  top: 250px;
  left: 500px;
  width: 60px;
  height: 180px;
`

export const MoonCrater2 = styled(MoonCrater)`
  top: 650px;
  left: 340px;
  width: 40px;
  height: 80px;
  transform: rotate(55deg);
`

export const MoonCrater3 = styled(MoonCrater)`
  top: -20px;
  left: 40px;
  width: 65px;
  height: 120px;
  transform: rotate(250deg);
`

export const Star = styled.div`
  background: grey;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  transform: rotate(250deg);
  opacity: 0.4;
  animation-name: shimmer;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  @keyframes shimmer {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.7;
    }
  }
`

export const Star1 = styled.div`
  top: 40%;
  left: 50%;
  animation-delay: 1s;
`

export const Star2 = styled.div`
  top: 60%;
  left: 90%;
  animation-delay: 3s;
`

export const Star3 = styled.div`
  top: 10%;
  left: 70%;
  animation-delay: 2s;
`

export const Star4 = styled.div`
  top: 90%;
  left: 40%;
`

export const Star5 = styled.div`
  top: 20%;
  left: 30%;
  animation-delay: 0.5s;
`

export const Error = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: white;
  height: 100%;
  position: relative;
  z-index: 2;
  margin-left: 8rem;
  @media (max-width: 768px) {
    margin-left: 4rem;
  }
  @media (max-width: 576px) {
    margin: 0 1rem;
    top: -4rem;
  }
`

export const Astronaut = styled.div`
  position: absolute;
  width: 185px;
  height: 300px;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(20deg) scale(1.2);

  @media (max-width: 768px) {
    top: unset;
    bottom: 0;
  }

  @media (max-width: 576px) {
    transform: translate(-50%, -20%) rotate(20deg) scale(1.2);
  }
`

export const AstronautHead = styled.div`
  background-color: white;
  position: absolute;
  top: 60px;
  left: 60px;
  width: 60px;
  height: 60px;
  border-radius: 2em;
`

export const AstronautHeadFlare1 = styled.div`
  background-color: #7f8fa6;
  position: absolute;
  top: 28px;
  left: 40px;
  width: 10px;
  height: 10px;
  border-radius: 2em;
  opacity: 0.5;
`

export const AstronautHeadFlare2 = styled.div`
  background-color: #718093;
  position: absolute;
  top: 40px;
  left: 38px;
  width: 5px;
  height: 5px;
  border-radius: 2em;
  opacity: 0.3;
`

export const AstronautBackpack = styled.div`
  background-color: #bfbfbf;
  position: absolute;
  top: 90px;
  left: 47px;
  width: 86px;
  height: 90px;
  border-radius: 8px;
`

export const AstronautBody = styled.div`
  background-color: #e6e6e6;
  position: absolute;
  top: 115px;
  left: 55px;
  width: 70px;
  height: 80px;
  border-radius: 8px;
`

export const AstronautBodyChest = styled.div`
  background-color: #d9d9d9;
  position: absolute;
  top: 140px;
  left: 68px;
  width: 45px;
  height: 25px;
  border-radius: 6px;
`

export const AstronautArmLeft1 = styled.div`
  background-color: #e6e6e6;
  position: absolute;
  top: 127px;
  left: 9px;
  width: 65px;
  height: 20px;
  border-radius: 8px;
  transform: rotate(-30deg);
`

export const AstronautArmLeft2 = styled.div`
  background-color: #e6e6e6;
  position: absolute;
  top: 102px;
  left: 7px;
  width: 20px;
  height: 45px;
  border-radius: 8px;
  transform: rotate(-12deg);
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
`

export const AstronautArmRight1 = styled.div`
  background-color: #e6e6e6;
  position: absolute;
  top: 113px;
  left: 100px;
  width: 65px;
  height: 20px;
  border-radius: 8px;
  transform: rotate(-10deg);
`

export const AstronautArmRight2 = styled.div`
  background-color: #e6e6e6;
  position: absolute;
  top: 78px;
  left: 141px;
  width: 20px;
  height: 45px;
  border-radius: 8px;
  transform: rotate(-10deg);
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
`

export const AstronautArmThumbLeft = styled.div`
  background-color: #e6e6e6;
  position: absolute;
  top: 110px;
  left: 21px;
  width: 10px;
  height: 6px;
  border-radius: 8em;
  transform: rotate(-35deg);
`

export const AstronautArmThumbRight = styled.div`
  background-color: #e6e6e6;
  position: absolute;
  top: 90px;
  left: 133px;
  width: 10px;
  height: 6px;
  border-radius: 8em;
  transform: rotate(20deg);
`

export const AstronautWristLeft = styled.div`
  background-color: #e67e22;
  position: absolute;
  top: 122px;
  left: 6.5px;
  width: 21px;
  height: 4px;
  border-radius: 8em;
  transform: rotate(-15deg);
`

export const AstronautWristRight = styled.div`
  background-color: #e67e22;
  position: absolute;
  top: 98px;
  left: 141px;
  width: 21px;
  height: 4px;
  border-radius: 8em;
  transform: rotate(-10deg);
`

export const AstronautLegLeft = styled.div`
  background-color: #e6e6e6;
  position: absolute;
  top: 188px;
  left: 50px;
  width: 23px;
  height: 75px;
  transform: rotate(10deg);
`

export const AstronautLegRight = styled.div`
  background-color: #e6e6e6;
  position: absolute;
  top: 188px;
  left: 108px;
  width: 23px;
  height: 75px;
  transform: rotate(-10deg);
`

export const AstronautFootLeft = styled.div`
  background-color: white;
  position: absolute;
  top: 240px;
  left: 43px;
  width: 28px;
  height: 20px;
  transform: rotate(10deg);
  border-radius: 3px;
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
  border-bottom: 4px solid #e67e22;
`

export const AstronautFootRight = styled.div`
  background-color: white;
  position: absolute;
  top: 240px;
  left: 111px;
  width: 28px;
  height: 20px;
  transform: rotate(-10deg);
  border-radius: 3px;
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
  border-bottom: 4px solid #e67e22;
`

export const AstronautCord = styled.div`
  overflow: hidden;
  width: 500px;
`
